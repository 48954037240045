(function () {
  'use strict';

  class TransactionsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    transactions() {
      return this.$resource(`${this.env.api}/transactions`);
    }

  }

  /**
   * @ngdoc service
   * @name components.service:TransactionsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('TransactionsRest', TransactionsRest);
}());
